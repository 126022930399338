// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

require("jquery")
require("@nathanvda/cocoon")

require("foundation-sites");
$(document).on('turbolinks:load', function () {
    $(function () { $(document).foundation(); });
});

require("app/stickyHeader")
require("app/submitFormOnChange");
require("app/dragScroll");
require("app/square");
require("app/processDiagram");
require("app/direct_uploads");
require("app/triggerFileAttached");
require("app/setDataIndexForMediaInputs");
require("app/shiftAddAssociationButtonAfterInsert");
+require("app/fullScreenSlideshow");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);