import React from "react";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from 'video-react';
import "video-react/styles/scss/video-react";

class Video extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      playing: false,
      player: null
    }
    this.player = React.createRef();
    this.onPlay = this.onPlay.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
  }

  // componentDidMount() {
  //   // console.log(this.player);
  //   // subscribe state change
  //   // this.player.subscribeToStateChange(this.handleStateChange);
  // }

  // handleStateChange(state){
  //   this.setState({ player: state });
  // }

  // onPlay(){
  //   // toggle the video fullscreen and plkay
  //   if(!this.state.playing){
  //     this.player.toggleFullscreen();
  //     this.player.play();
  //     this.setState({playing: true});
  //   }
  // }

  render () {
    
    let { src, poster, klass, id, play } = this.props;

    return <Player
    playsInline
    poster="/assets/poster.png"
    src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
  />

    return (
      <div id='hero-container'>
        <img src={poster} alt='Learning For Resilience Video Poster' className='hero'/>

        <img onClick={this.onPlay} src={play} alt='Play Button' className='play'/>

        <Player
          // ref={player => {
          //   this.player = player;
          // }}
          src={src}
          // poster={poster ? poster : null}
          // className='hidden'
        />
      </div>
    )
  }
}

Video.propTypes = {
  src: PropTypes.string.isRequired
};

export default Video;
