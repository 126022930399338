import React from "react"
import { Player, BigPlayButton } from 'video-react';

class Video extends React.Component {
  render () {
    let { src, poster} = this.props;

    return (
      <Player
        ref={player => {
          this.player = player;
        }}
        className='hero'
        src={src}
        fluid
        poster={poster ? poster : null}
      >
        <BigPlayButton position='center' />
      </Player>
    );
  }
}

export default Video
