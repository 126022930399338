// Shift the add button to after the newly created field
document.addEventListener('turbolinks:load', function(e) {
  $(document).on('cocoon:after-insert', function(e) {
    const container = e.target;
    const button = container.querySelector('.add_fields');
    const newFields = document.querySelectorAll('.nested-fields');
    const newField = newFields[newFields.length - 1];
    console.log(newField);
    if(container && button && newField){
      container.appendChild(newField);
      container.appendChild(button);
    }
  })
});