const initSlideshow = () => {
  const slideshow = document.querySelectorAll(".slideshow-item");

  if(slideshow.length > 0){
    const next = document.querySelector(".slideshow-button-next");
    const prev = document.querySelector(".slideshow-button-prev");
    let position = 0;
  
    // These methods must be passed within anonymous functions for some reason.
    if(next) next.addEventListener("click", () => incrementPosition());
    if(prev) prev.addEventListener("click", () => decrementPosition());
  
    const incrementPosition = () => {
      if (position === slideshow.length - 1) position = 0;
      else position++;
      updateSlideshow();
    }
  
    const decrementPosition = () => {
      if (position === 0) position = slideshow.length - 1;
      else position--;
      updateSlideshow();
    }
  
    const updateSlideshow = () => {
      for (let i = 0; i < slideshow.length; i++) {
        slideshow[i].classList.add("hidden");
      }
      slideshow[position].classList.remove("hidden");
    }  

    resetSlideshowOnClose();
  }
}

const resetSlideshowOnClose = () => {
  const closeButton = document.querySelector('#study-slideshow-modal button.close-button');
  if(closeButton) closeButton.addEventListener('click', resetSlideshow)
}

const resetSlideshow = () => {
  const slideshow = document.querySelectorAll(".slideshow-item");
  for(let i = 0; i < slideshow.length; i++){
    slideshow[i].classList.add('hidden');
  }
  slideshow[0].classList.remove('hidden');
}

document.addEventListener("turbolinks:load", initSlideshow);


