import React from "react"
import PropTypes from "prop-types"
import { Player, BigPlayButton } from 'video-react';

class Video extends React.Component {

  onSmall() {
    return window.innerWidth < 640;
  }

  render () {
    let { src, poster, limitHeight } = this.props;

    let fluid = true;
    let height = 'auto';

    if(limitHeight){
      fluid = false;
      height = this.onSmall() ? 250 : window.innerWidth * 0.5;
      if(height > 550) height = 550;
    }

    return (
      <div id='hero-container'>

        <Player
          ref={player => {
            this.player = player;
          }}
          className='hero'
          src={src}
          fluid={fluid}
          width='100%'
          height={height}
          poster={poster ? poster : null}
        >
          <BigPlayButton position='center' />
        </Player>
      </div>
      
    );
  }
}

export default Video
