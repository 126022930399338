const DEFAULT = `
  <h3 class="white text-center">Process Overview</h3>
  <p>
  As a transdicisplinary and international team, we drew on the skills, experience and expertise of each member to create a robust offer for resilience education.
  </p>
  <p>
  Our process combines research, curriculum and pedagogy, recognising the value of integrating real-world experiences, building on current research, and adopting experiential approaches to teaching and learning. 
  </p>
  <p class="italic light nmb">
    Hover over each aspect of the diagram to learn more.
  </p>
`

const PROCESS = {
  research: `
  <h3 class="white text-center">Research</h3>
  <p class="nmb">
    In support of developing our education for community resilience, we have published several significant pieces of statistically rigorous research in peer-reviewed journals. We asked fundamental questions before starting the journey such as 'what literature supports resilience education?' 'Does a common language exist for resilience?' & 'How to deliver community resilience'
  </p>
  `,
  curriculum: `
  <h3 class="white text-center">Curriculum</h3>
  <p class="nmb">
    Curriculum is the content, lessons, and structured resources that enable learners to develop skills and mastery of a subject area. Here we focus on the design of inspiring, contextually rich, transdisciplinary based scenarios that bring the outside world into the classroom.
  </p>
  `,
  pedagogy: `
  <h3 class="white text-center">Pedagogy</h3>
  <p class="nmb">
  Pedagogy is essential to the learner's journey. Here we place pedagogy central in the design and delivery of our educational offering for community resilience. Adopting a design education approach, we value the role of experiential learning, critically-engaged practice and reflection.
  </p>
  `
}

const processDiagram = () => {
  const circles = document.querySelectorAll('.process-circle');
  for (let i = 0; i < circles.length; i++) {
    const circle = circles[i];
    circle.addEventListener('mouseover', showInfo)
    circle.addEventListener('mouseleave', clearInfo)
  }
}

const showInfo = (e) => {
  const circle = e.target;
  const info = document.querySelector('#process-info');
  if (info) info.innerHTML = PROCESS[circle.id];
  styleCurrent(circle);
}

const styleCurrent = (circle) => {
  circle.setAttribute('fill', '#1C5C89');
  const text = document.querySelector('#' + circle.id + 'Label');
  if (text) text.setAttribute('fill', 'white');
}

const clearInfo = (e) => {
  const circle = e.target;
  const info = document.querySelector('#process-info');
  if (info) info.innerHTML = DEFAULT;
  clearStyle(circle);
}

const clearStyle = (circle) => {
  circle.setAttribute('fill', 'transparent');
  const text = document.querySelector('#' + circle.id + 'Label');
  if (text) text.setAttribute('fill', 'black');
}

document.addEventListener('turbolinks:load', processDiagram)