document.addEventListener("turbolinks:load", function () {
  const slider = document.querySelector(".slider");
  
  if(slider){
    slider.style.scrollBehavior = "smooth";

    const leftArrow = document.querySelector(".left-arrow");
    const item = document.querySelectorAll(".scroll-item")[0];
    const rightArrow = document.querySelector(".right-arrow");

    if(slider){
      const disable = (el) => {
        el.classList.add("disabled");
      }

      const enable = (el) => {
        el.classList.remove("disabled");
      }

      const checkDisable = () => {
        const fullRight = (slider.scrollLeft - slider.offsetWidth) > -5;
        if(slider.scrollLeft == 0){
          disable(leftArrow);
          enable(rightArrow);
        } else if(fullRight){
          disable(rightArrow);
          enable(leftArrow);
        } else {
          enable(leftArrow);
          enable(rightArrow);
        }
      }
    
      leftArrow.addEventListener("click", () => {
        slider.scrollLeft -= item.offsetWidth;
        setTimeout(checkDisable, 500);
      });
      
      rightArrow.addEventListener("click", () => {
        slider.scrollLeft += item.offsetWidth;
        setTimeout(checkDisable, 500);
      });

      slider.addEventListener('scroll', () => {
        setTimeout(checkDisable, 500);
      })
    }
  }
  
});
